<template>
  <el-form :model="form" :rules="rules" ref="form" label-width="100px" size="small">
    <el-row>
      <el-col :span="24">
        <el-form-item label="日期" prop="zhixing_date">
          <el-date-picker
            v-model="form.zhixing_date"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
            :picker-options="pickerOptions1"
          ></el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="24">
        <el-form-item label="执行情况" prop="zhixing_qingkuang">
          <el-input type="textarea" :rows="6" v-model="form.zhixing_qingkuang"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item>
      <div style="float:right;">
        <el-button @click="resetForm()"> 取消 </el-button>
        <el-button type="primary" @click="submitForm()"> 确认添加 </el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {},
      rules: {
        zhixing_qingkuang: [{ required: true, message: '请填写执行情况', trigger: 'blur' }],
        zhixing_date: [{ required: true, message: '请选择日期', trigger: 'blur' }]
      },
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  methods: {
    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          console.log('validation ok!')
          this.$emit('submitForm', this.form)
          this.form = {}
        } else {
          console.log('Validation error!')
          return false
        }
      })
    },
    resetForm() {
      this.$refs['form'].resetFields()
      this.$emit('resetForm')
    }
  },
  created() {}
}
</script>

<style lang="scss" scoped></style>
